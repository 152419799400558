import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTheme } from '@mui/material';
import { Outlet, useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import PageFrameContainer from '@shared/ui/layout/PageFrameContainer';
import { useGetProfileByNicknameQuery } from '@shared/api/profile';
import { ContainerColumn, ContainerRow } from '@components/styled';
import ProfileTabsWidget from '@widgets/ProfileTabsWidget';
import { ProfilePreview } from '@entities/profile';
import { useAppSelector } from '@store/Hooks';
import { ReportUser } from '@features/profile/report';
import CopyLink from '@shared/ui/misc/CopyLink';
import { ReactComponent as CopyLinkIcon } from '@icons/wolfkit-light/link-alt-light.svg';
import withTextEllipsis from '@shared/ui/tooltips/withTextEllipsis';
import { BodySemiBold } from '@components/styled/Typography';
import Button from '@shared/ui/buttons/Button';
import { IsDefined } from '@utils/js-ts';
const ProfileHeaderContainer = styled(ContainerColumn)(() => ({
    height: 'auto',
    minHeight: '280px',
    justifyContent: 'space-between',
    gap: '48px',
}));
const ContentContainer = styled(ContainerRow)(() => ({
    height: 'auto',
    justifyContent: 'space-between',
}));
const ButtonsContainer = styled(ContainerRow)(props => ({
    flexShrink: 0,
    width: 'auto',
    height: 'auto',
    gap: props.theme.spacing_sizes.s,
}));
const ButtonText = withTextEllipsis(BodySemiBold);
const ButtonStyled = styled(Button)(() => ({
    maxWidth: 124,
}));
const Profile = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { nickname } = useParams();
    const { currentUserProfile } = useAppSelector((state) => state.profile);
    const { currentData: profile, isLoading, isError, } = useGetProfileByNicknameQuery({ nickname: nickname }, {
        refetchOnMountOrArgChange: true,
        skip: (nickname === null || nickname === void 0 ? void 0 : nickname.length) === 0,
    });
    const displayError = isError || (!isLoading && !IsDefined(profile));
    const isUserOwner = (currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.id) === (profile === null || profile === void 0 ? void 0 : profile.id);
    const profileLink = document.location.href;
    return (_jsx(PageFrameContainer, { header: (!!profile && (_jsxs(ProfileHeaderContainer, { children: [_jsx(ContentContainer, { children: _jsx(ProfilePreview, { profile: profile, isLoading: isLoading, actionBtns: !isUserOwner && (_jsxs(ButtonsContainer, { children: [_jsx(CopyLink, { link: profileLink, children: _jsx(ButtonStyled, { variant: 'tinted', color: 'primary', size: 'small', startIcon: CopyLinkIcon, children: _jsx(ButtonText, { tooltipText: t('actions.copy_link', { ns: 'common' }), letterSpacing: '-0.084px', children: t('actions.copy_link', { ns: 'common' }) }) }) }), _jsx(ReportUser, {})] })) }) }), _jsx(ProfileTabsWidget, { profileNickname: profile.username })] }))), gap: theme.spacing_sizes.l, headerStyles: {
            padding: `${theme.spacing_sizes.s * 10}px 0 0`,
        }, bottom: (_jsx(Outlet, { context: {
                profileId: profile === null || profile === void 0 ? void 0 : profile.id,
                nickname,
                profileType: (profile === null || profile === void 0 ? void 0 : profile.type) || 'undefined',
            } })), error: displayError ? 'not_found' : undefined }));
};
export default Profile;
